import { useQuery } from 'react-query'
import { format, parseISO } from 'date-fns'

export default function useMaintenanceInfo(appName) {
  return useQuery(`Maintenance Info ${appName}`, () =>
    callGetMaintenanceInfo(appName)
  )
}

const callGetMaintenanceInfo = async (appName) => {
  const maintenanceInfoApiURL = `${process.env.REACT_APP_MAINTENANCE_SERVICE_URL}/maintenance?appName=${appName}`

  const response = await fetch(maintenanceInfoApiURL)

  if (!response.ok) {
    const message = `MaintenanceNotification Service error has occured - ${response.status}: ${response.statusText}`
    throw new Error(message)
  }

  const resultList = await response.json()

  if (resultList) {
    for (const result of resultList) {
      if (result.startDate) {
        const sDate = parseISO(
          result.startDate,
          'yyyy-MM-dd HH:mm:ss',
          new Date()
        )
        const eDate = parseISO(
          result.endDate,
          'yyyy-MM-dd HH:mm:ss',
          new Date()
        )
        const startDate = format(sDate, 'MM-dd-yyyy').toString()
        const startTime = format(sDate, 'hh:mm a').toString()
        const endDate = format(eDate, 'MM-dd-yyyy').toString()
        const endTime = format(eDate, 'hh:mm a').toString()
        result.subMessage = `from ${startDate} ${startTime} to ${endDate} ${endTime}`
      } else {
        result.subMessage = ''
      }
    }
    return resultList
  } else {
    throw new Error(
      `MaintenanceNotification Service : No messages available for ${appName}`
    )
  }
}
