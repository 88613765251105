import React from 'react'
import './index.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Maintenance } from './components/Maintenance'
import PropTypes from 'prop-types'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked'
    }
  }
})

const MaintenanceNotification = ({ appName }) => (
  <QueryClientProvider client={queryClient}>
    <Maintenance appName={appName} />
  </QueryClientProvider>
)

MaintenanceNotification.propTypes = {
  appName: PropTypes.string.isRequired
}

export { MaintenanceNotification }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
