import React from 'react'
import useMaintenanceInfo from '../hooks/useMaintenanceInfo'
import MaintenanceSegment from './MaintenanceSegment'

export const Maintenance = ({ appName }) => {
  const { data, isLoading, isError } = useMaintenanceInfo(appName)

  if (isLoading) {
    return null
  }

  if (isError) {
    return null
  }

  return data ? (
    <div className='maintanence-main'>
      {data.map((element, index) => {
        return <MaintenanceSegment key={index} data={element} />
      })}
    </div>
  ) : null
}
