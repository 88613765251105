import React, { useState, useEffect } from 'react'
import { MdNotifications } from 'react-icons/md'
import { RiCloseCircleFill } from 'react-icons/ri'

const MaintenanceSegment = ({ data }) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false)
    }, 45000)

    return () => clearTimeout(timer)
  }, [])

  if (!show) {
    return null
  }

  return (
    <div className='maintenance-container'>
      <div className='maintenance-align-content maintenance-justify'>
        <div className='maintenance-align-content'>
          <div className='maintanence-icon-div'>
            <MdNotifications />
          </div>
          <div>{`${data.message} ${data.subMessage}`}</div>
        </div>
        <div className='maintanence-icon-div'>
          <RiCloseCircleFill onClick={() => setShow(false)} />
        </div>
      </div>
    </div>
  )
}

export default MaintenanceSegment
